import HeadingChip from '@/@core/components/HeadingChip';
import BulbIcon from '@/@core/icons/landing/solution/BulbIcon';
import { Box, Typography } from '@mui/material';
import AIIcon from '../../../public/images/landing/solution/AIIcon.svg';
import Collection from '../../../public/images/landing/solution/AutonomusCollection.svg';
import Account from '../../../public/images/landing/solution/Account.svg';
import Financial from '../../../public/images/landing/solution/Financial.svg';
import Graph from '../../../public/images/landing/solution/graph.svg';
import users from '../../../public/images/landing/solution/users.svg';
import Image from 'next/image';

const data = [
  {
    icon: <Image src={AIIcon} alt='AI Icon' />,
    heading: 'AI Automation & Workflow Optimization',
    subHeading:
      'Categorize customers based on different characteristics, generate personalized workflows around them, and analyze the results to optimize the collection process',
    sectors: 'Fintech, SMBs, Healthcare',
    link: '/ai-automation-and-workflow-optimization',
  },
  {
    icon: <Image src={Collection} alt='AI Icon' />,
    heading: 'Autonomous collections',
    subHeading:
      'Let Alice handle all your collections end to end without your agents having to intervene unless absolutely necessary.',
    sectors: 'Fintech, LawFirms, Healthcare, SMBs',
    link: '/autonomus-collections',
  },
  {
    icon: <Image src={Account} alt='AI Icon' />,
    heading: 'Accounts Receivable Management',
    subHeading:
      'Leverage AI to create, verify, and send invoices automatically and identify potential delays in upcoming payments to manage cash flow.',
    sectors: 'Credit Unions, SMBs, Fintech',
    link: '/accounts-receivable-management',
  },
  {
    icon: <Image src={Financial} alt='AI Icon' />,
    heading: 'Financial Reporting & Analysis',
    subHeading:
      'Generate comprehensive reports such as revenues, expenses, profitability, and team performance over specified periods for better financial oversight.',
    sectors: 'Healthcare, SMBs, LawFirms',
    link: '/financial-reporting-and-analysis',
  },
  {
    icon: <Image src={Graph} alt='AI Icon' />,
    heading: 'Advanced Risk Management',
    subHeading:
      'Identify at-risk customers in advance by getting insights into customer behaviour and sentiment to reduce chances of delinquencies.',
    sectors: 'SMBs, Credit Union, Fintech',
    link: '/advanced-risk-management',
  },
  {
    icon: <Image src={users} alt='AI Icon' />,
    heading: 'Customer Engagement & Self-service',
    subHeading:
      'Manage customer queries and disputes with our AI-powered resolution center while also offering round the clock customer support.',
    sectors: 'SMBs, Fintech, Credit Unions',
    link: '/customer-engagement-and-self-service',
  },
];

import { useRouter } from 'next/router';

const OurSolutions = () => {
  const router = useRouter();

  return (
    <Box
      sx={{
        background: 'rgba(100, 97, 243, 0.15)',
        py: '80px',
      }}
    >
      <Box
        sx={{
          width: '1000px',
          maxWidth: '90vw',
          margin: '0 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HeadingChip text='Our Solutions' icon={BulbIcon} />
        <Typography
          sx={{
            color: '#1C1F25',
            fontSize: '4rem',
            fontWeight: '700',
            pt: '16px',
            pb: '10px',
            lineHeight: 1,
          }}
        >
          One Platform for all your
        </Typography>
        <Typography
          sx={{
            color: '#1C1F25',
            fontSize: '4.8rem',
            fontWeight: '700',
            pb: '16px',
            lineHeight: 1,
          }}
        >
          CFO back office needs
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '683px',
          }}
        >
          <Typography
            sx={{
              color: '#4B5162',
              fontSize: '1.6rem',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            Modernize your back office financial operations with AI. Reduce your cost of collections, and ensure
            regulatory compliance, including disputes, payments, payables, cash management, and more.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            rowGap: '32px',
            flexWrap: 'wrap',
            justifyContent: 'center',
            mt: '48px',
            pb: '20px',
          }}
        >
          {data.map((item, index) => {
            return (
              <Box
                onClick={() => {
                  router.push(item.link);
                }}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  border: '1px solid #B6BCCD;',
                  borderRadius: '12px',
                  padding: '32px',
                  background: '#fff',
                  maxWidth: '415px',
                  transition: '0.2s all',

                  '&:hover': {
                    border: '1px solid #6461F3',
                    boxShadow: '8px 3px 9px rgba(100, 97, 243, 0.2)',
                    cursor: 'pointer',
                  },
                }}
              >
                {item.icon}
                <Typography
                  sx={{
                    color: '#1C1F25',
                    fontWeight: '700',
                    fontSize: '1.8rem',
                  }}
                >
                  {item.heading}
                </Typography>
                <Typography
                  sx={{
                    color: '#4B5162',
                    fontWeight: '400',
                    fontSize: '1.6rem',
                  }}
                >
                  {item.subHeading}
                </Typography>
                <Typography
                  sx={{
                    color: '#6461F3',
                    fontWeight: '400',
                    fontSize: '1.4rem',
                  }}
                >
                  {item.sectors}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default OurSolutions;
