import NavigationBar from '@/components/navigation/NavigationBar';
import { Box, useTheme, useMediaQuery, Typography, TextField } from '@mui/material';
import Image from 'next/image';
import banner from '../../../public/images/landing/macbook.svg';
import mobile from '../../../public/images/landing/mobile.svg';
import BookDemoButton from '@/@core/components/BookDemoButton';
import { useState } from 'react';
import { useRouter } from 'next/router';

const Stats = [
  {
    value: '10x',
    description: 'team’s output without increasing the workload',
  },
  {
    value: '24*7',
    description: 'Uninterrupted Collections',
  },
  {
    value: '80%',
    description: 'Reduction in OPEX',
  },
];

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  const isLaptop = useMediaQuery(theme.breakpoints.down(1500));

  const [email, setEmail] = useState('');

  const router = useRouter();
  return (
    <Box
      sx={{
        background: 'linear-gradient(114.656deg, #1F1933 0%, #6461F3 100%)',
        position: 'relative',
        pb: '120px',
      }}
    >
      <Box>
        <Box
          sx={{
            maxWidth: '1000px',
            margin: '0 auto',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <NavigationBar bookButtonVariant='new' buttonText='Get a demo' />
          <Box
            sx={{
              display: 'flex',
              pt: isTab ? '30px' : '60px',
              flexDirection: isTab ? 'column' : 'row',
              ...(isTab && {
                gap: '60px',
              }),
            }}
          >
            <Box
              sx={{
                maxWidth: isTab ? '100%' : '50%',
                boxShadow: '590px -100px 123px rgba(255, 105, 180, 0.5)',
              }}
            >
              <Typography
                sx={{
                  fontSize: '4.8rem',
                  color: theme.palette.common.white,
                  fontWeight: 700,
                  lineHeight: 1,
                  pt: '10px',
                }}
              >
                Transforming Back-Office Financial Operations with Genesis One!
              </Typography>
              <Typography
                sx={{
                  fontSize: '2.4rem',
                  color: theme.palette.common.white,
                  fontWeight: 400,
                  pt: '1.8rem',
                  pb: '2.8rem',
                }}
              >
                With a little help from AI
              </Typography>
              <Box sx={{ maxWidth: '460px' }}>
                <Typography
                  sx={{
                    fontSize: '1.6rem',
                    color: theme.palette.common.white,
                    fontWeight: 400,
                  }}
                >
                  Automate collections, streamline receivables, and generate insightful reports—transforming your
                  financial operations from end to end
                </Typography>
                <Box
                  sx={{
                    mt: '50px',
                    zIndex: '100',
                    '& .MuiInputBase-root': {
                      width: '328px',
                      maxWidth: '85vw',
                      background: 'rgba(239, 239, 239, 0.28);',
                      fontSize: '1.2rem',
                      borderRadius: '8px',
                      border: '1px rgba(111, 111, 111, 0.25) !important',
                      color: '#fff',
                      height: '42px',
                      '& fieldset': {
                        border: 'none !important',
                      },
                    },
                    '& .MuiButtonBase-root': {
                      width: isMobile ? 'auto' : '102px',
                      height: '42px',
                      fontSize: '1.2rem',
                      borderRadius: '8px',
                      color: '#fff',
                      background:
                        ' linear-gradient(92.687deg, rgba(255, 255, 255, 13%) 0%, rgba(255, 255, 255, 25%) 100%)',
                      fontWeight: '700',
                      transition: 'background 1s ease-in-out, transform 0.3s ease-in-out',
                      '&:hover': {
                        background:
                          'linear-gradient(92.687deg, rgba(31, 25, 51, 50%) 0%, rgba(100, 97, 243, 50%) 100%)',
                      },
                    },
                  }}
                >
                  <TextField
                    placeholder='Your work email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Box
                          sx={{
                            position: 'relative',
                            right: '-14px',
                          }}
                        >
                          <BookDemoButton
                            text={isMobile ? 'Start' : 'Get a demo'}
                            onClick={() => {
                              router.push(`/contact-us?email=${email}`);
                            }}
                          />
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  transform: 'scale(1.3)',
                  position: 'absolute',
                  top: '70px',
                  left: isMobile ? '17%' : isTab ? '38%' : '31px',
                  width: isMobile ? '60vw' : isTab ? '30vw' : isLaptop ? '400px' : '600px',
                  maxWidth: isLaptop ? '700px' : '1000px',
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0 auto',
                  zIndex: 3,
                }}
              >
                <Image src={banner} alt={'FinanceOps'} layout='responsive' />
                <Box sx={{ position: 'absolute', bottom: 0, right: '70px', width: '20%' }}>
                  <Image src={mobile} alt={'FinanceOps'} layout='responsive' />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          margin: '0 auto',
          marginTop: isTab ? '300px' : isLaptop ? '100px' : '215px',
        }}
      >
        <Box sx={{ margin: '0 auto', pt: '30px' }}>
          <Box sx={{ display: 'flex', gap: isMobile ? '24px' : '54px', justifyContent: 'center', flexWrap: 'wrap' }}>
            {Stats.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    width: '231px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? '3.2rem' : '7.2rem',
                      fontWeight: '700',
                      color: theme.palette.common.white,
                      lineHeight: 1,
                    }}
                  >
                    {item.value}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.6rem',
                      fontWeight: '400',
                      color: theme.palette.common.white,
                      lineHeight: 1,
                      textAlign: 'center',
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
