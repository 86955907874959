import HeadingChip from '@/@core/components/HeadingChip';
import { Box, Typography } from '@mui/material';
import netSuite from '../../../public/images/landing/logos/netSuite.svg';
import qbIcon from '../../../public/images/landing/logos/qbIcon.svg';
import sage from '../../../public/images/landing/logos/sage.svg';
import salesforce from '../../../public/images/landing/logos/salesforce.svg';
import xero from '../../../public/images/landing/logos/xero.svg';
import Image from 'next/image';
import IntegrationsIcon from '@/@core/icons/landing/solution/Integrations';

const Integrations = () => {
  return (
    <Box
      sx={{
        background: '#fff',
        py: '80px',
      }}
    >
      <Box
        sx={{
          width: '900px',
          maxWidth: '90vw',
          margin: '0 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <HeadingChip text='Integrations' icon={IntegrationsIcon} />
        <Typography
          sx={{
            color: '#1F1933',
            fontSize: '4rem',
            fontWeight: '400',
            pt: '16px',
            pb: '10px',
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          {' '}
          Integrated with industry best service providers
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '683px',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            gap: '42px',
            rowGap: '32px',
            flexWrap: 'wrap',
            justifyContent: 'center',
            mt: '48px',
          }}
        >
          {[netSuite, qbIcon, sage, salesforce, xero].map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image src={item} key={index} alt='a' />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Integrations;
