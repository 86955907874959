const AgentIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8333 1.66602C16.1023 2.91859 17.0807 3.89704 18.3333 4.16602C17.0807 4.43499 16.1023 5.41344 15.8333 6.66602C15.5643 5.41344 14.5859 4.43499 13.3333 4.16602C14.5859 3.89704 15.5643 2.91859 15.8333 1.66602ZM7.56 1.83268C8.31958 5.36995 11.0827 8.1331 14.62 8.89268C11.0827 9.65227 8.31958 12.4154 7.56 15.9527C6.80042 12.4154 4.03726 9.65227 0.5 8.89268C4.03726 8.1331 6.80042 5.36995 7.56 1.83268ZM18.3333 14.9993C16.6632 14.6407 15.3586 13.3361 15 11.666C14.6413 13.3361 13.3367 14.6407 11.6666 14.9993C13.3367 15.358 14.6413 16.6626 15 18.3327C15.3586 16.6626 16.6632 15.358 18.3333 14.9993Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default AgentIcon;
