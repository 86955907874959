const UsersIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        d='M15.9989 2.92969C13.5136 2.92969 11.4989 4.94469 11.4989 7.42969C11.4989 9.91469 13.5136 11.9297 15.9989 11.9297C18.4842 11.9297 20.4989 9.91469 20.4989 7.42969C20.4989 4.94469 18.4842 2.92969 15.9989 2.92969ZM8.4989 3.92969C6.2898 3.92969 4.4989 5.72069 4.4989 7.92969C4.4989 10.1387 6.2898 11.9297 8.4989 11.9297C9.3283 11.9297 10.0854 11.7017 10.7517 11.2357C9.0429 8.69572 9.2851 6.26369 10.2164 4.30469C9.6845 4.05269 9.1003 3.92969 8.4989 3.92969ZM5.5614 13.2737C3.7273 14.0307 2.4989 15.8857 2.4989 17.9297V19.9297C2.4989 20.4817 2.9466 20.9297 3.4989 20.9297L7.6517 20.9317C7.4177 20.1717 7.4865 18.9397 7.5019 17.9427C7.5344 15.8257 8.4099 14.3157 8.7528 13.9177C8.1226 13.9127 7.3663 13.7597 6.8114 13.5237C6.6774 13.4667 6.5996 13.4217 6.3427 13.3047C6.0908 13.1897 5.8172 13.1677 5.5614 13.2737ZM12.5614 13.2737C10.7273 14.0307 9.4989 15.8857 9.4989 17.9297V19.9297C9.4989 20.4817 9.9466 20.9297 10.4989 20.9297H21.4989C22.0512 20.9297 22.4989 20.4817 22.4989 19.9297V17.9297C22.4989 15.9337 21.2637 14.1237 19.4364 13.3357C19.1792 13.2247 18.8795 13.2217 18.6239 13.3357C17.7391 13.7327 16.8771 13.9297 15.9989 13.9297C15.1237 13.9297 14.2587 13.7217 13.3427 13.3047C13.0908 13.1897 12.8172 13.1677 12.5614 13.2737Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default UsersIcon;
