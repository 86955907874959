const SearchIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.16663 8.97061C4.16665 6.67861 5.78576 4.70582 8.03374 4.25876C10.2817 3.81171 12.5324 5.0149 13.4094 7.13249C14.2864 9.25009 13.5453 11.6923 11.6394 12.9654C9.73359 14.2386 7.19377 13.9882 5.57329 12.3673C4.67259 11.4663 4.16661 10.2446 4.16663 8.97061Z'
        stroke='#6461F3'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.7742 13.7734L15.8333 15.8326'
        stroke='#6461F3'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SearchIcon;
