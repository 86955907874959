import HeadingChip from '@/@core/components/HeadingChip';
import SearchIcon from '@/@core/icons/landing/solution/Search';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const list = [
  {
    question: 'What is FinanceOps and how is it different?',
    answer:
      'We are building the predictive AI engine for the finance function. Our technology enables businesses to scale without having to increase their back-office finance spend.',
  },
  {
    question: 'How does FinanceOps Work?',
    answer:
      'Our platform analyzes customer profiles, characteristics, and engagement to formulate collections strategies that would be most effective for that debtor profile, thereby increasing recovery rates.',
  },
  {
    question: 'Is my data secure with FinanceOps?',
    answer:
      'Absolutely! At FinanceOps, we prioritize data security. All data processed and stored on our platform is encrypted using industry-standard protocols. Additionally, we have stringent access controls in place to ensure that only authorized personnel can access sensitive information.',
  },
  {
    question: 'Can I integrate FinanceOps with other tools and platforms I currently use?',
    answer:
      'Yes, FinanceOps is designed to seamlessly integrate with a wide range of business tools, CRMs, ERPs, and other platforms. Our goal is to provide a unified analytics experience, ensuring you have a holistic view of your operations.',
  },
  {
    question: 'How is the pricing structured for FinanceOps? Are there any hidden fees?',
    answer:
      'FinanceOps offers transparent pricing with various plans to cater to different business needs. All our pricing details are listed on the "Pricing" page. There are no hidden fees, and you can choose a plan that best fits your requirements.',
  },
  {
    question: "I'm new to finance function. Does FinanceOps offer any support or tutorials?",
    answer:
      "Absolutely! We understand that analytics can be overwhelming, especially if you're new to it. Our aim is to make your FinanceOps experience as smooth and beneficial as possible.",
  },
];

const Faqs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  const [openedIndexes, setOpendIndexes] = useState<number[]>([]);

  const toggleIndex = (index: number) => {
    setOpendIndexes(prevOpenedList =>
      prevOpenedList.includes(index) ? prevOpenedList.filter(i => i !== index) : [...prevOpenedList, index],
    );
  };

  return (
    <Box sx={{ p: '128px 32px 112px' }}>
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          gap: '80px',
          ...(isTab && {
            flexDirection: 'column',
          }),
        }}
      >
        <Box sx={{ pr: '40px', maxWidth: '436px' }}>
          <Box sx={{ maxWidth: 'fit-content' }}>
            <HeadingChip text='FAQ' icon={SearchIcon} />
          </Box>
          <Typography
            sx={{
              color: '#1C1F25',
              fontSize: '3.9rem',
              fontWeight: '700',
              py: '16px',
            }}
          >
            Frequently asked questions
          </Typography>
          <Typography
            sx={{
              color: '#4B5162',
              fontSize: '1.6rem',
              fontWeight: '400',
            }}
          >
            Explore our frequently asked questions to learn more about FinanceOp’s features, security, integration
            capabilities, and more
          </Typography>
        </Box>
        <Box sx={{ width: isTab ? '100%' : '60%' }}>
          {list?.map((item, index) => (
            <Box
              key={index}
              sx={{
                borderBottom: '1px solid #B6BCCD',
                py: '20px',
                transition: 'border-bottom 0.3s ease, max-height 0.5s ease, padding 0.5s ease',
                '&:hover': {
                  borderBottom: `1px solid ${theme.palette.primary.main}`,
                },
              }}
            >
              <Box
                onClick={() => toggleIndex(index)}
                sx={{
                  cursor: 'pointer',
                  fontSize: '2rem',
                  fontWeight: '700',
                  color: '#1C1F25',
                  position: 'relative',
                  pl: '10px',
                  lineHeight: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ maxWidth: '80%' }}>{item.question}</Box>
                <Box sx={{ pr: '20px' }}>
                  {openedIndexes.includes(index) ? (
                    <ExpandLessIcon sx={{ fontSize: '16px' }} />
                  ) : (
                    <ExpandMoreIcon sx={{ fontSize: '16px' }} />
                  )}
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    left: '-10px',
                    width: '4px',
                    top: '0',
                    bottom: 0,
                    borderRadius: '2px',
                    background: '#6461F3',
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                  height: openedIndexes.includes(index) ? 'auto' : 0,
                  maxHeight: openedIndexes.includes(index) ? '1000px' : '0',
                  transition: 'max-height 0.5s ease, padding 0.5s ease',
                  paddingTop: openedIndexes.includes(index) ? '24px' : '0',
                }}
              >
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontSize: '1.6rem',
                    fontWeight: '400',
                    color: '#4B5162',
                  }}
                >
                  {item.answer}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Faqs;
