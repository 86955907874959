import { Box, Typography } from '@mui/material';
import { ComponentType } from 'react';

interface HeadingChipProps {
  icon: ComponentType;
  text: string;
}

const HeadingChip = ({ icon: Icon, text }: HeadingChipProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '42px',
        boxShadow:
          '0px 0px 1px 0px rgba(44, 58, 114, 0.05), 0px 2px 6px 0px rgba(44, 58, 114, 0.05), 0px 10px 18px 0px rgba(58, 76, 146, 0.10);',
        border: '1px solid #B6BCCD',
        gap: '10px',
        borderRadius: '20px',
        px: '12px',
        alignItems: 'center',
        background: '#fff',
      }}
    >
      <Icon />
      <Typography
        sx={{
          color: '#4B5162',
          fontSize: '1.6rem',
          fontWeight: '500',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default HeadingChip;
